<template>
	<div
		v-if="options.length > 0"
		class="row my-3">
		<div class="col">
			<div class="row no-gutters">
				<div class="col">
					<p class="mb-2">
						{{ title }}
					</p>
					<div
						:class="{
							'row': inVariant(['text']),
							'px-3': inVariant(['text']) && !['xs','sm','md'].includes(windowWidth),
						}">
						<div
							v-for="(option, index) in options"
							:key="index"
							:class="{
								'col-6': inVariant(['text']) && ['xs','sm','md'].includes(windowWidth),
								'pr-0': inVariant(['text']) && ['xs','sm','md'].includes(windowWidth),
								'd-inline': inVariant(['color', 'size']) || !['xs','sm','md'].includes(windowWidth),
								'col-auto': inVariant(['color', 'size']) || !['xs','sm','md'].includes(windowWidth),
								'pl-0': inVariant(['color', 'size']) || !['xs','sm','md'].includes(windowWidth)
							}">
							<button
								v-if="inVariant(['text'])"
								:style="['xs','sm','md'].includes(windowWidth) ? 'width:100%;' : ''"
								class="btn text-decoration-none mb-2"
								:class="selectedOption === option.value ? 'branded-button' : 'branded-button-outline'"
								@click="selectedOption = option.value">
								<span class="font-weight-bold mb-0 p-1 h6">
									{{ option.text }}
								</span>
							</button>
							<b-button
								v-else-if="inVariant(['color'])"
								variant="link"
								:class="selectedOption === option.value ? 'color-active' : ''"
								:style="`background-color: ${option.value}; border: solid 1px ${mapColor(option.value)};`"
								class="text-decoration-none rounded-circle color-rounded-btn p-1"
								@click="selectedOption = option.value">
								<div
									:style="`background-color: transparent; border: solid 1px ${selectedOption === option.value ? mapColor(option.value) : 'transparent'}`"
									class="w-100 h-100 rounded-circle" />
							</b-button>
							<b-button
								v-else-if="inVariant(['size'])"
								variant="link"
								:class="selectedOption === option.value ? 'rounded-btn-active' : ''"
								class="text-decoration-none rounded-circle rounded-btn p-1"
								@click="selectedOption = option.value">
								<small class="font-weight-bold"> {{ option.text }} </small>
							</b-button>
						</div>
					</div>
				</div>
			</div>
			<!-- Slot to add more rows -->
			<slot />
		</div>
	</div>
</template>
<script>
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'OptionSelect',
	mixins: [WindowSizes],
	props: {
		title: {
			type: String,
			default: '',
		},
		options: {
			type: Array,
			default: () => [],
		},
		selectFirst: {
			type: Boolean,
			default: true,
		},
		variant: {
			type: String,
			default: 'text',
			validator(value) {
				return ['text', 'color', 'size'].includes(value);
			},
		},
		// The option to be selected, if exists in options values
		initialSelectedOption: {
			type: [String, undefined],
			default: undefined,
		},
	},
	data() {
		return {
			selectedOption: null,
		};
	},
	watch: {
		selectedOption() {
			this.$emit('update:selectedOption', this.selectedOption);
		},
		options(newOpts) {
			if (newOpts.findIndex((opt) => opt.value === this.selectedOption) === -1) {
				this.selectFirstItem();
			}
		},
		initialSelectedOption() {
			this.selectedOption = this.initialSelectedOption;
		},
	},
	mounted() {
		this.selectDefaultItem();
	},
	methods: {
		inVariant(variants) {
			return variants.includes(this.variant);
		},
		selectDefaultItem() {
			if (typeof this.initialSelectedOption !== 'undefined') {
				const toSelect = this.options.find((option) => option.value === this.initialSelectedOption);
				if (toSelect) {
					this.selectedOption = toSelect.value;
				} else {
					this.selectFirstItem();
				}
			} else {
				this.selectFirstItem();
			}
		},
		selectFirstItem() {
			if (this.selectFirst && this.options.length > 0) {
				this.selectedOption = this.options[0].value;
			}
		},
		mapColor(color) {
			switch (color) {
			case 'white': return 'gray';
			default: return color;
			}
		},
	},
};
</script>
<style scoped>
.rounded-btn {
	color: #73818f;
}
.rounded-btn:hover {
	color: #ffffff;
}
.rounded-btn-active {
	color: #ffffff;
}
.color-rounded-btn {
	width: 35px;
	height: 35px;
}
.color-active {
	padding: 4px !important;
	background-clip: content-box;
}
</style>
