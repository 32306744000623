var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.options.length > 0)?_c('div',{staticClass:"row my-3"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{class:{
						'row': _vm.inVariant(['text']),
						'px-3': _vm.inVariant(['text']) && !['xs','sm','md'].includes(_vm.windowWidth),
					}},_vm._l((_vm.options),function(option,index){return _c('div',{key:index,class:{
							'col-6': _vm.inVariant(['text']) && ['xs','sm','md'].includes(_vm.windowWidth),
							'pr-0': _vm.inVariant(['text']) && ['xs','sm','md'].includes(_vm.windowWidth),
							'd-inline': _vm.inVariant(['color', 'size']) || !['xs','sm','md'].includes(_vm.windowWidth),
							'col-auto': _vm.inVariant(['color', 'size']) || !['xs','sm','md'].includes(_vm.windowWidth),
							'pl-0': _vm.inVariant(['color', 'size']) || !['xs','sm','md'].includes(_vm.windowWidth)
						}},[(_vm.inVariant(['text']))?_c('button',{staticClass:"btn text-decoration-none mb-2",class:_vm.selectedOption === option.value ? 'branded-button' : 'branded-button-outline',style:(['xs','sm','md'].includes(_vm.windowWidth) ? 'width:100%;' : ''),on:{"click":function($event){_vm.selectedOption = option.value}}},[_c('span',{staticClass:"font-weight-bold mb-0 p-1 h6"},[_vm._v(" "+_vm._s(option.text)+" ")])]):(_vm.inVariant(['color']))?_c('b-button',{staticClass:"text-decoration-none rounded-circle color-rounded-btn p-1",class:_vm.selectedOption === option.value ? 'color-active' : '',style:(("background-color: " + (option.value) + "; border: solid 1px " + (_vm.mapColor(option.value)) + ";")),attrs:{"variant":"link"},on:{"click":function($event){_vm.selectedOption = option.value}}},[_c('div',{staticClass:"w-100 h-100 rounded-circle",style:(("background-color: transparent; border: solid 1px " + (_vm.selectedOption === option.value ? _vm.mapColor(option.value) : 'transparent')))})]):(_vm.inVariant(['size']))?_c('b-button',{staticClass:"text-decoration-none rounded-circle rounded-btn p-1",class:_vm.selectedOption === option.value ? 'rounded-btn-active' : '',attrs:{"variant":"link"},on:{"click":function($event){_vm.selectedOption = option.value}}},[_c('small',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(option.text)+" ")])]):_vm._e()],1)}),0)])]),_vm._t("default")],2)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }